import React from 'react'
import css from './Video.module.css'
import myVD from '../../assets/bg.mp4'
function Video() {
    return (
        <div>
            <video playsInline autoPlay muted loop className={css.MyVideo}>
            <source src={myVD} type="video/mp4" />
          </video>
        </div>
    )
}

export default Video
