import React from 'react';
import css from './Menu.module.css';
import MenuItem from '../MenuItem/MenuItem';
import { Link } from 'react-scroll';

function Menu() {
  return (
    <nav className={css.Menu}>
      <Link
        to="intro"
        style={{ textDecoration: 'none' }}
        activeClass={css.active}
        spy={true}
        smooth={true}
        duration={300}
        offset={50}
      >
        <MenuItem>About</MenuItem>
      </Link>
      {/* <Link 
            style={{ textDecoration: 'none' }} 
             to="works"
                 activeClass={css.active}
                spy={true}
                smooth={true}
                duration={300}
>
            <MenuItem>Portfolio</MenuItem>
            </Link>
            <Link style={{ textDecoration: 'none' }}  
            to="contact"
                 activeClass={css.active}
                spy={true}
                smooth={true}
                duration={300}
>
            <MenuItem>Contact</MenuItem>
            </Link> */}
    </nav>
  );
}

export default Menu;
