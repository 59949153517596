import React, { Suspense } from 'react';
import css from './App.module.css';
import Menu from './components/Menu/Menu';
import Intro from './components/Intro/Intro';
import Works from './components/Works/Works';
import Contact from './components/Contact/Contact';
import { DataStore } from './context';
import Video from './components/Video/Video';
import Sidebar from './components/Sidebar/Sidebar';
import BurgerMenu from './components/BurgerMenu/BurgerMenu';
function App() {
  return (
    <div className={css.App}>
      <DataStore>
        <Suspense
          fallback={
            <div
              style={{
                display: 'grid',
                placeContent: 'center',
                textAlign: 'center',
                color: 'white',
              }}
            >
              Loading...
            </div>
          }
        >
          <Video />
          <Menu />
          <Intro />
          {/* <Works/>
            <Contact/> */}
          <Sidebar />
          <BurgerMenu />
        </Suspense>
      </DataStore>
      <script src="/__/firebase/8.0.1/firebase-app.js"></script>
      <script src="/__/firebase/8.0.1/firebase-analytics.js"></script>
      <script src="/__/firebase/init.js"></script>
    </div>
  );
}

export default App;
