import React from "react";
import {Helmet} from "react-helmet";

const Meta =()=>{

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" name="description" content="Online Portfolio of Soyombo Enkhutr, Front-end  developer" />
                <title>Soyombo | Portfolio</title>

            </Helmet>
        </div>
    );
};
export default Meta