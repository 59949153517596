import React, { useState, useEffect, useRef } from 'react';
import css from './Intro.module.css';
import GlitchClip from 'react-glitch-effect/core/Clip';
import meImg from '../../assets/image/me.JPEG';
import ubImg from '../../assets/image/ub.jpg';
import sfImg from '../../assets/image/sf.jpg';
import devImg from '../../assets/image/developer.png';

function Intro() {
  const [state, setState] = useState(0);
  const imgRef1 = useRef();
  const imgRef2 = useRef();
  const imgRef3 = useRef();
  const imgRef4 = useRef();
  const showImg = (e) => {
    setState(e);
  };
  const hideImg = () => {
    setState(0);
  };
  useEffect(() => {
    if (state === 1) {
      imgRef1.current.style.display = 'block';
      imgRef2.current.style.display = 'none';
      imgRef3.current.style.display = 'none';
      imgRef4.current.style.display = 'none';
    } else if (state === 2) {
      imgRef1.current.style.display = 'none';
      imgRef2.current.style.display = 'block';
      imgRef3.current.style.display = 'none';
      imgRef4.current.style.display = 'none';
    } else if (state === 3) {
      imgRef1.current.style.display = 'none';
      imgRef2.current.style.display = 'none';
      imgRef3.current.style.display = 'block';
      imgRef4.current.style.display = 'none';
    } else if (state === 4) {
      imgRef1.current.style.display = 'none';
      imgRef2.current.style.display = 'none';
      imgRef3.current.style.display = 'none';
      imgRef4.current.style.display = 'block';
    } else {
      imgRef1.current.style.display = 'none';
      imgRef2.current.style.display = 'none';
      imgRef3.current.style.display = 'none';
      imgRef4.current.style.display = 'none';
    }
  }, [state]);

  return (
    <div id="intro" className={css.Intro}>
      <div className={css.Imgwrapper}>
        <GlitchClip>
          <img ref={imgRef1} src={meImg} alt="me" />
          <img ref={imgRef2} src={ubImg} alt="ulan bator" />
          <img ref={imgRef3} src={sfImg} alt="san francisco" />
          <img ref={imgRef4} src={devImg} alt="developer" />
        </GlitchClip>
      </div>
      <div className={css.Text}>
        <GlitchClip>
          <h1 data-aos-anchor-placement="top-bottom">Welcome,</h1>
        </GlitchClip>
        <div onMouseLeave={hideImg} data-aos="fade-down">
          <div
            onMouseEnter={() => {
              showImg(1);
            }}
          >
            I'm{' '}
            <span style={{ color: '#f0d53b', fontWeight: 'bolder' }}>
              Soyombo
            </span>{' '}
            Enkhtur
          </div>

          <div
            onMouseEnter={() => {
              showImg(2);
            }}
          >
            {' '}
            Born in{' '}
            <span style={{ color: '#f0d53b', fontWeight: 'bolder' }}>
              Ulaanbaatar
            </span>
            , Mongolia
          </div>

          <div
            onMouseEnter={() => {
              showImg(3);
            }}
          >
            {' '}
            Living in{' '}
            <span style={{ color: '#f0d53b', fontWeight: 'bolder' }}>
              San Francisco
            </span>
            , California
          </div>

          <div
            onMouseEnter={() => {
              showImg(4);
            }}
          >
            Energetic{' '}
            <span style={{ color: '#f0d53b', fontWeight: 'bolder' }}>
              full-stack developer
            </span>{' '}
            with a passion for web development
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
