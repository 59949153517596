import React, { useContext } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import css from './Contact.module.css';
import DataContext from '../../context';
import Spinner from '../Spinner/Spinner';

function Contact() {
  const dataCtx = useContext(DataContext);
  return (
    <div id="contact" className={css.Contact}>
      {/* <div
        data-aos="zoom-in-up"
        data-aos-easing="ease-out-sine"
        data-aos-anchor-placement="center-bottom"
        data-aos-duration="800"
        className={css.ContactWrap}
      >
        <h2>I'd love to hear from you</h2>
        <p>I'm here to help and answer any questions you might have</p>
        {dataCtx.saving ? <Spinner /> : null}
        <Formik
          initialValues={{ name: '', email: '', title: '', textarea: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
              dataCtx.saveComment(JSON.stringify(values, null, 4));
              setSubmitting(false);
              resetForm((values = ''));
            }, 400);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Field type="name" name="name" placeholder="Name" />
              <ErrorMessage name="name" component="div" />
              <Field type="email" name="email" placeholder="Email" />
              <ErrorMessage name="email" component="div" />
              <Field type="title" name="title" placeholder="Title" />
              <ErrorMessage name="name" component="div" />
              <Field
                type="textarea"
                name="textarea"
                placeholder="Message"
                component="textarea"
              />
              <ErrorMessage name="name" component="div" />
            </Form>
          )}
        </Formik>
        <button
          className="g-recaptcha"
          data-sitekey="reCAPTCHA_site_key"
          data-callback="onSubmit"
          data-action="submit"
        >
          Submit
        </button>
      </div> */}
    </div>
  );
}

export default Contact;
