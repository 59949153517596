import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Meta from './components/Meta/Meta'
import App from './App';
import reportWebVitals from './reportWebVitals';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init();
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate( 
    <React.StrictMode>
          <Meta/>
      <App />

    </React.StrictMode>
   , rootElement);
} else {
  ReactDOM.render( 
   
    <React.StrictMode>
      <App />
    </React.StrictMode>
   , rootElement);
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
