import React, { useContext } from 'react';
import MenuItem from '../MenuItem/MenuItem';
import { Link } from 'react-scroll';
import DataContext from '../../context';
import css from './Sidebar.module.css';

function Sidebar() {
  const ctx = useContext(DataContext);
  let classes = [css.Sidebar, css.Open];
  if (ctx.state.sidebar) {
    classes = [css.Sidebar, css.Close];
  }

  return (
    <div className={classes.join(' ')}>
      <Link
        to="intro"
        style={{ textDecoration: 'none' }}
        activeClass={css.active}
        spy={true}
        smooth={true}
        onClick={ctx.toggleMenu}
        duration={300}
      >
        <MenuItem>About</MenuItem>
      </Link>
      {/* <Link 
            style={{ textDecoration: 'none' }} 
             to="works"
                 activeClass={css.active}
                spy={true}
                smooth={true}
                onClick={ctx.toggleMenu} 
                duration={300}
>
            <MenuItem>Portfolio</MenuItem>
            </Link>
            <Link style={{ textDecoration: 'none' }}  
            to="contact"
                 activeClass={css.active}
                spy={true}
                smooth={true}
                onClick={ctx.toggleMenu} 
                duration={300}
>
            <MenuItem>Contact</MenuItem>
            </Link> */}
    </div>
  );
}

export default Sidebar;
