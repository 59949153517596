import React ,{useContext}from 'react'
import css from './BurgerMenu.module.css'
import DataContext from '../../context'
function BurgerMenu() {
    const ctx = useContext(DataContext)
    let classes =[css.BurgerMenu, css.Open]
if(ctx.state.sidebar){
    classes = [css.BurgerMenu, css.Close]
}
    return (
        <div onClick={ctx.toggleMenu} className={classes.join(" ")}>
          <div></div>
          <div></div>
          <div></div>
        </div>
    )
}

export default BurgerMenu
