import React,{ useState} from 'react'
import axios from './myAxios'
import burgerImg from './assets/image/burger.png'
import counterImg from './assets/image/counter.png'
import recipeImg from './assets/image/recipe.png'
import lyftImg from './assets/image/lyft.png'
const DataContext = React.createContext();
const initialState={
    saving: false,
    sidebar: true,
    workItems: {
        burger:{
            imgSrc:burgerImg,
            imgAlt:'Burger',
            title:'ONLINE BURGER ORDERING WEB APP',
            text:<div>This is an online burger ordering single page application created in REACT JS. App has over 30 components, including some high order components. For sign-up and login, I used Firebase Authentication. For the store and sync data, I used a firebase real-time database. For burger illustration, I used Adobe Illustrator.
            <br/>
            Skills used in this application:  <br/>
            Error handling, Axios, Routing, React Hooks, React Context, saving token to localStorage, Lazy Loading & optimization, firebase deployment and etc,.`</div>,
            urlLink:`https://burger-6e771.web.app/login`
        },
        counter:{
            imgSrc:counterImg,
            imgAlt:'counter',
            title:'COUNTER APP',
            text:`I created this web app with Vanilla Javascript, CSS, and HTML. It saves your data to localStorage. This web app helps you to count reps during workout.`,
            urlLink:`https://elegant-hamilton-8536c1.netlify.app/`
        },
        lyft:{
            imgSrc:lyftImg,
            imgAlt:'Lyft company',
            title:'LYFT CLONE LANDING PAGE',
            text:`This is a fully responsive clone landing page of Lyft company. I used React to create it.`,
            urlLink:`https://lyft-landingpage-clone.web.app/`
        },
        recipe:{
            imgSrc:recipeImg,
            imgAlt:'RECIPE FINDER WEB APP',
            title:'RECIPE FINDER WEB APP',
            text:<div>I created this project with Vanilla Javascript. This project will serve best for the people who use digital tools to plan their cooking. The search result fetches data from Forky API. The search results contain information about the ingredients list, the total time needed for cooking, the user’s rating, and cooking directions. Architecture is a model-view-controller (MVC).
                <br/>
            Skills used in this project:  <br/>
            EcmaScript 6, arrow function, ES6 class, Promise, JS symbol, default parameter values, Rest Parameter, ways to manage arrays, objects by ES6 features and etc,.`</div>,
            urlLink:`https://yosombo.github.io/recipe/`
        }
    }
}
export const DataStore = props=>{
const[state, setState]=useState(initialState)

const toggleMenu = ()=>{
  setState({...state, sidebar: !state.sidebar})
  console.log(state);
}

   const saveComment=(msg)=>{
       setState({...state, saving: true})
       axios.post(`/messages.json`, msg)
       .then(response => {
        setState({...state, saving: false})

    }).catch(error =>{
   console.log(error);
    })
   }

return(
    <DataContext.Provider value={{state, saveComment, toggleMenu}}>
        {props.children}
    </DataContext.Provider>
)
}
export default DataContext
