import React from 'react'
import css from './MenuItem.module.css'
function MenuItem(props) {

    return (
      
        <div className={css.MenuItem}>
            {props.children}
        </div>
     
    )
}

export default MenuItem
